import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { storage } from "../services/Firebase";
import QuestionHeader from "./QuestionHeader";
import Modal from "react-bootstrap/Modal";
import { isSafari, isIOS, isMobile } from "react-device-detect";
import DisplayQRCode from "./DisplayQRCode";
import { PageContext } from "../context/PageContext";
import LoadingQuestion from "./LoadingQuestion";
import EmailLinkModal from "./EmailLinkModal";
import { qrLinkUrl, surveyQrLinkData } from "../services/SendGridService";
import errorReporter from "../services/ErrorReporter";
import { getModerationStatus } from "../utils/ModerationHelper";
import NotApplicableCheckbox from "./NotApplicableCheckbox";
import { Alert } from "react-bootstrap";

function Video(props) {
  const { question, setAnswerData, answer } = props;

  const linkUrl = qrLinkUrl();
  const emailData = surveyQrLinkData();
  const deviceType = question.deviceType ?? "mobile";

  const [, dispatch] = useContext(PageContext);
  const [showRecorder, setShowRecorder] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [isVideoUploading, setIsVideoUploading] = useState(false);
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isAnswerLoaded, setIsAnswerLoaded] = useState(false);
  const [showEmailModal, setEmailModal] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [showNoVideoDataModal, setShowNoVideoDataModal] = useState(false);
  const [showVideoFailureMessage, setShowVideoFailureMessage] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(false);

  const cancelUpload = useCallback(() => {
    setAnswerData(
      question.id,
      {
        isComplete: false,
        isUploading: false,
        data: null,
        meta: null,
      },
      true,
      { collectClientData: true }
    );
    setIsVideoUploading(false);
    setShowCancelButton(false);
    setShowVideoFailureMessage(true);
  }, [question.id, setAnswerData]);

  const showVideoRecorder = () => {
    dispatch({ type: "RECORD_VIDEO_START" });
    setShowRecorder(true);
  };

  const hideVideoRecorder = () => {
    dispatch({ type: "RECORD_VIDEO_STOP" });
    setShowRecorder(false);
  };

  const resetAfterError = () => {
    setShowPermissionModal(false);
    setShowNoVideoDataModal(false);
    window.location.reload();
  };

  const hideVideoPreview = () => {
    setShowVideoFailureMessage(false);
    setShowPreview(false);
  };

  const deleteVideo = () => {
    hideVideoPreview();
    setAnswerData(
      question.id,
      {
        isComplete: false,
        isUploading: false,
        data: null,
        meta: null,
      },
      true,
      { collectClientData: true }
    );
  };

  const uploadVideo = (blob) => {
    const uuidFilename = uuidv4();
    const imageRef = ref(storage, uuidFilename);

    setAnswerData(question.id, { isUploading: true, meta: null }, true);
    setShowVideoFailureMessage(false);
    setIsVideoUploading(true);

    var jsonRequest = JSON.stringify({ size: blob?.size, type: blob?.type });
    var error = new Error("Video upload starting: " + jsonRequest);
    error.name = "Video Upload Start";
    errorReporter.report(error);

    let uploadTask = uploadBytesResumable(imageRef, blob);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100) || 0;
        setUploadProgress(progress);
        console.log("Upload is " + progress + "% done", snapshot);
      },
      (error) => {
        console.error("error message", error.message);
        errorReporter.report(error);
        setIsVideoUploading(false);
      },
      () => {
        let response = uploadTask.snapshot;

        setIsVideoUploading(false);

        getDownloadURL(imageRef)
          .then((downloadURL) => {
            console.log("File available at", downloadURL);

            let data = Object.assign(response.metadata, { downloadUrl: downloadURL });

            let answerData = {
              //screen: { width: window.screen.width, height: window.screen.height },
              //browser: { width: window.innerWidth, height: window.innerHeight },
              isComplete: true,
              data: {
                contentType: data.contentType,
                downloadUrl: data.downloadUrl,
                name: data.name,
                size: data.size,
                timeCreated: data.timeCreated,
                notApplicable: false,
              },
              meta: {
                media: {
                  type: "video",
                  isAcceptable: true,
                  moderationStatus: getModerationStatus(question.acceptable?.media, true, "needsreview"),
                },
                notApplicable: undefined,
              },
              isUploading: false,
            };

            //console.log("data", data);
            var jsonResponse = JSON.stringify(data);
            var error = new Error("Video context: " + jsonResponse);
            error.name = "Video Upload Finished";
            errorReporter.report(error);

            const videoIsValid = data.size > 0;
            if (!videoIsValid) {
              console.error("Video upload failed. Video size is 0.");
              errorReporter.report(new Error("Video upload failed. Video size is 0."));
              setShowVideoFailureMessage(true);
            } else {
              setShowVideoFailureMessage(false);
              setAnswerData(question.id, answerData, videoIsValid, { collectClientData: true });
            }
          })
          .catch((error) => {
            console.error("Error getting download URL", error);
            errorReporter.report(error);
            setIsVideoUploading(false);
            setShowVideoFailureMessage(true);
          });
      }
    );
  };

  useEffect(() => {
    if (isVideoUploading) {
      dispatch({ type: "SURVEYNAV_REMOVE" });
    } else {
      dispatch({ type: "SURVEYNAV_ADD" });
    }
  }, [dispatch, isVideoUploading]);

  useEffect(() => {
    setIsVideoUploaded(answer?.data?.downloadUrl);
    setShowPreview(answer?.data?.downloadUrl);
    setIsAnswerLoaded(answer);
  }, [answer]);

  // Check if the upload is stuck, show cancel button after 15 seconds.
  useEffect(() => {
    if (answer && answer.isUploading) {
      const timeoutId = setTimeout(() => {
        if (uploadProgress === 0) {
          setShowCancelButton(true);
        }
      }, 15000);
      return () => clearTimeout(timeoutId);
    }
  }, [answer, cancelUpload, uploadProgress]);

  // Check if the upload is stuck, close upload and show error modal after 60 seconds.
  useEffect(() => {
    if (answer && answer.isUploading) {
      // Set a timeout to check if the upload is stuck
      const timeoutId = setTimeout(() => {
        if (uploadProgress === 0) {
          console.warn("Upload appears to be stuck, triggering error handling.");
          cancelUpload();
        }
      }, 60000);
      return () => clearTimeout(timeoutId);
    }
  }, [answer, cancelUpload, uploadProgress]);

  const cancel = (errorMessage) => {
    hideVideoRecorder();
    setShowPreview(answer?.data?.downloadUrl);

    if (errorMessage) {
      switch (errorMessage) {
        case "NO_VIDEO_DATA":
          setShowNoVideoDataModal(true);
          break;
        default:
          setShowPermissionModal(true);
      }

      console.error(errorMessage);
    }
  };

  const save = (blob) => {
    if (!blob || blob.size === 0) {
      console.error("Video blob is empty or invalid.");
      errorReporter.report(new Error("Video blob is empty or invalid."));
      setShowVideoFailureMessage(true);
      return;
    }
    console.log("~~saving", blob);
    uploadVideo(blob);
    hideVideoRecorder();
  };

  const IOS_HELP = () => {
    if (isSafari) {
      return <IOS_SAFARI />;
    } else {
      return <IOS_CHROME />;
    }
  };

  const IOS_SAFARI = () => {
    return (
      <>
        <span className="gps-step">
          Touch the <small>A</small>A icon in the address bar
        </span>
        <span className="material-symbols-outlined">south</span>
        <span className="gps-step">Website Settings</span>
        <span className="material-symbols-outlined">south</span>
        <span className="gps-step">Set Camera &amp; Microphone to Allow</span>
      </>
    );
  };

  const IOS_CHROME = () => {
    return (
      <>
        <span className="gps-step">Go to Settings</span>
        <span className="material-symbols-outlined">south</span>
        <span className="gps-step">Privacy</span>
        <span className="material-symbols-outlined">south</span>
        <span className="gps-step">Camera</span>
        <span className="material-symbols-outlined">south</span>
        <span className="gps-step">Enable Chrome</span>
        <span className="material-symbols-outlined">south</span>
        <span className="gps-step">Go Back</span>
        <span className="material-symbols-outlined">south</span>
        <span className="gps-step">Microphone</span>
        <span className="material-symbols-outlined">south</span>
        <span className="gps-step">Enable Chrome</span>
      </>
    );
  };

  const VideoFailureAlert = () => {
    return (
      <>
        {showVideoFailureMessage && (
          <>
            <Alert variant="danger" onClose={() => setShowVideoFailureMessage(false)} dismissible>
              <Alert.Heading>Video Upload Error</Alert.Heading>
              <p>
                Unable to upload your video. Please ensure it's at least 1 second long and fully processed, then try
                again. For persistent issues, restarting your device might help.
              </p>
            </Alert>
          </>
        )}
      </>
    );
  };

  const EnablePermissionsModal = (
    <Modal show={showPermissionModal} onHide={() => resetAfterError()} centered backdrop="static">
      {/* <button type="button" className="btn-close" aria-label="Close" onClick={props.onHide}></button> */}
      <Modal.Header closeButton>
        <Modal.Title className="text-center">Enable Video Permissions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          In order to capture a video, we need access to your device's camera and microphone. Please follow these steps
          to authorize it:
        </p>
        <p className="enable-gps-steps mt-4">
          {isIOS ? (
            <IOS_HELP />
          ) : (
            <>
              <span className="gps-step">Go to Settings</span>
              <span className="material-symbols-outlined">south</span>
              <span className="gps-step">Site Settings</span>
              <span className="material-symbols-outlined">south</span>
              <span className="gps-step">Microphone or Camera</span>
              <span className="material-symbols-outlined">south</span>
              <span className="gps-step">Turn the microphone or camera on</span>
            </>
          )}
        </p>
      </Modal.Body>
    </Modal>
  );

  const NoVideoDataModal = (
    <Modal show={showNoVideoDataModal} onHide={() => resetAfterError()} centered backdrop="static">
      {/* <button type="button" className="btn-close" aria-label="Close" onClick={props.onHide}></button> */}
      <Modal.Header closeButton>
        <Modal.Title className="text-center">Microphone or Camera in use</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          In order to capture a video, we need access to your device's camera and microphone. Please ensure that you are
          not on a phone call and that no other applications using the camera or microphone.
        </p>
      </Modal.Body>
    </Modal>
  );

  const showNotApplicableCheckbox = !(question?.required ?? true);

  const InitialScreen = () => {
    const showVideoButton = isMobile || deviceType === "desktop";

    return (
      <div className="d-flex flex-column align-items-center h-100">
        <QuestionHeader question={question} isVideoUploaded={isVideoUploaded} />

        {EnablePermissionsModal}

        {NoVideoDataModal}

        {isVideoUploaded && showPreview && answer?.data?.downloadUrl ? (
          <div className="uploaded-video w-100 h-100">
            {
              // Render the video failure alert, this can happen if it fails to upload on a retry.
              VideoFailureAlert()
            }
            {answer?.data?.timeCreated && (
              <p className="small text-center mb-2">Uploaded on {new Date(answer.data.timeCreated).toLocaleString()}</p>
            )}
            <div className="video-preview position-relative w-100">
              <button
                // onClick={() => showVideoRecorder()}
                onClick={() => deleteVideo()}
                className="btn-delete d-flex align-items-center fw-600 btn btn-outline-danger btn-sm btn-pill px-3"
              >
                <svg
                  className="me-2"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_124_1344)">
                    <path d="M13.625 2.375H11.2812V1.90625C11.2812 1.13084 10.6504 0.5 9.875 0.5H6.125C5.34959 0.5 4.71875 1.13084 4.71875 1.90625V2.375H2.375C1.59959 2.375 0.96875 3.00584 0.96875 3.78125C0.96875 4.404 1.37578 4.93316 1.93766 5.11753L2.77375 15.2105C2.83397 15.9336 3.44953 16.5 4.17513 16.5H11.8249C12.5505 16.5 13.1661 15.9336 13.2263 15.2103L14.0623 5.1175C14.6242 4.93316 15.0312 4.404 15.0312 3.78125C15.0312 3.00584 14.4004 2.375 13.625 2.375ZM5.65625 1.90625C5.65625 1.64778 5.86653 1.4375 6.125 1.4375H9.875C10.1335 1.4375 10.3438 1.64778 10.3438 1.90625V2.375H5.65625V1.90625ZM12.292 15.1327C12.2719 15.3737 12.0667 15.5625 11.8249 15.5625H4.17513C3.93328 15.5625 3.72809 15.3737 3.70806 15.1329L2.88419 5.1875H13.1158L12.292 15.1327ZM13.625 4.25H2.375C2.11653 4.25 1.90625 4.03972 1.90625 3.78125C1.90625 3.52278 2.11653 3.3125 2.375 3.3125H13.625C13.8835 3.3125 14.0938 3.52278 14.0938 3.78125C14.0938 4.03972 13.8835 4.25 13.625 4.25Z" />
                    <path d="M6.12409 14.1273L5.65534 6.56477C5.63931 6.30636 5.41566 6.10983 5.1585 6.12592C4.90009 6.14195 4.70363 6.36439 4.71963 6.62277L5.18837 14.1853C5.20378 14.4338 5.41016 14.625 5.65578 14.625C5.92725 14.625 6.14075 14.3964 6.12409 14.1273Z" />
                    <path d="M8 6.125C7.74112 6.125 7.53125 6.33487 7.53125 6.59375V14.1562C7.53125 14.4151 7.74112 14.625 8 14.625C8.25888 14.625 8.46875 14.4151 8.46875 14.1562V6.59375C8.46875 6.33487 8.25888 6.125 8 6.125Z" />
                    <path d="M10.8415 6.12592C10.5837 6.10988 10.3606 6.30635 10.3446 6.56476L9.87588 14.1273C9.85991 14.3856 10.0564 14.6081 10.3147 14.6241C10.5733 14.6401 10.7956 14.4435 10.8116 14.1853L11.2803 6.62276C11.2963 6.36435 11.0999 6.14192 10.8415 6.12592Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0_124_1344">
                      <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                  </defs>
                </svg>
                Replace
              </button>

              <video preload="metadata" controls className="w-100 h-100 bg-white" style={{ minHeight: "470px" }}>
                <source src={`${answer.data.downloadUrl}#t=0.001`} type={answer.data.contentType} />
              </video>
            </div>
          </div>
        ) : (
          <div className="justify-content-center mt-3 w-100">
            <>
              {showVideoButton ? (
                <>
                  {
                    // Render the video failure alert
                    VideoFailureAlert()
                  }
                  <div className={`text-center`}>
                    <div className="card h-100 text-center border-0 shadow shadow-sm">
                      {showNotApplicableCheckbox && (
                        <NotApplicableCheckbox question={question} setAnswerData={setAnswerData} answer={answer} />
                      )}
                      <div className="card-body d-flex justify-content-center align-items-center p-4">
                        <div>
                          <button className="app-btn-secondary btn-circle" onClick={() => showVideoRecorder(true)}>
                            <svg
                              width="55"
                              height="55"
                              viewBox="0 0 48 48"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 40q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h26q1.2 0 2.1.9.9.9.9 2.1v10.75l8-8v20.5l-8-8V37q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h26V11H7v26Zm0 0V11v26Z" />
                            </svg>
                            <div className="mt-2">
                              <span className="d-block d-md-none">Start Video</span>
                              <span className="d-none d-md-block">Use Webcam</span>
                            </div>
                          </button>
                          <div className="mt-4">Click the button above to record a video with your device</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="">
                    <div className="card h-100 text-center border-0 shadow shadow-sm">
                      {showNotApplicableCheckbox && (
                        <NotApplicableCheckbox question={question} setAnswerData={setAnswerData} answer={answer} />
                      )}
                      <div className="card-body d-flex justify-content-center align-items-center p-4">
                        <DisplayQRCode linkUrl={linkUrl} question={question} />
                      </div>
                      <div className="card-footer">
                        <button
                          type="button"
                          className="btn btn-no-outline btn-link"
                          onClick={() => setEmailModal(true)}
                        >
                          <u>Having trouble using the QR code?</u>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>

            <EmailLinkModal
              emailData={{ subject: emailData.subject, html: emailData.html }}
              showModal={showEmailModal}
              toggleModal={() => setEmailModal(false)}
            />

            {/* <p className="separator d-none d-md-flex align-items-center justify-content-center mt-5 mb-3">
              <span className="text">OR</span>
            </p>
            <button
              className="app-btn-secondary btn w-100 mt-4 mx-auto"
              style={{ maxWidth: "300px" }}
              onClick={() => showVideoRecorder(true)}
            >
              <span className="d-block d-md-none">Start Video</span>
              <span className="d-none d-md-block">Use Webcam</span>
            </button> */}
          </div>
        )}
      </div>
    );
  };

  if (isVideoUploading) {
    return (
      <div className="text-center">
        <div className="w-100" style={{ top: "15%" }}>
          <h1 className="mt-5 mb-5">Uploading Video...</h1>
          <div className="progress rounded-pill mb-3">
            <div
              className="progress-bar bg-primary"
              role="progressbar"
              style={{ width: `${uploadProgress}%` }}
              aria-valuenow={uploadProgress}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <p className="fs-2 fw-600">{uploadProgress}%</p>
        </div>
        <div className="text-center mt-5">
          <div className="spinner-border app-spinner" role="status">
            <span className="visually-hidden">Uploading video</span>
          </div>
        </div>
        {showCancelButton && (
          <button onClick={cancelUpload} className="btn btn-danger mt-3">
            Cancel Upload
          </button>
        )}
      </div>
    );
  } else if (showRecorder) {
    return <VideoRecorder cancel={cancel} save={save} />;
  } else if (isAnswerLoaded) {
    return <InitialScreen />;
  } else {
    return <LoadingQuestion />;
  }
}

function VideoRecorder(props) {
  const mediaStream = useRef(null);
  const [status, setStatus] = useState("uninit");
  const mediaType = useRef(null);
  const mediaRecorder = useRef(null);
  const mediaChunks = useRef([]);

  const [showMediaRecorderModal, setShowMediaRecorderModal] = useState(
    isIOS && isSafari && window.MediaRecorder === undefined
  );

  const resetAfterMediaRecorder = () => {
    setShowMediaRecorderModal(false);
    window.location.reload();
  };

  const EnableMediaRecorderModal = () => {
    return (
      <Modal show={showMediaRecorderModal} onHide={() => resetAfterMediaRecorder()} centered backdrop="static">
        {/* <button type="button" className="btn-close" aria-label="Close" onClick={props.onHide}></button> */}
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Enable Media Recorder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            In order to record video, you must enable the MediaRecorder feature on your device. Please follow these
            steps:
          </p>
          <p className="enable-gps-steps mt-4">
            <span className="gps-step">Go to Settings</span>
            <span className="material-symbols-outlined">south</span>
            <span className="gps-step">Safari</span>
            <span className="material-symbols-outlined">south</span>
            <span className="gps-step">Advanced</span>
            <span className="material-symbols-outlined">south</span>
            <span className="gps-step">Experimental Features</span>
            <span className="material-symbols-outlined">south</span>
            <span className="gps-step">
              Find <strong>MediaRecorder</strong> in the list
            </span>
            <span className="material-symbols-outlined">south</span>
            <span className="gps-step">Change the toggle to green</span>
          </p>
        </Modal.Body>
      </Modal>
    );
  };

  const cancel = (errorMessage) => {
    mediaChunks.current = [];
    destroyStream();
    props.cancel(errorMessage);
  };

  const startRecording = () => {
    setStatus("recording");
    mediaRecorder.current.start(1000);
  };

  const stopRecording = () => {
    setStatus("stopped");
    mediaRecorder.current.stop();
  };

  const onRecordingActive = ({ data }) => {
    console.log("chunk", data);
    mediaChunks.current.push(data);

    if (mediaChunks.current.length > 1) {
      const missingData = mediaChunks.current.every((chunk) => {
        return chunk.size === 0;
      });

      if (missingData) {
        cancel("NO_VIDEO_DATA");
        errorReporter.report(new Error("MediaRecorder missing data"));
      }
    }
  };

  const findBestCodec = () => {
    let codecs = ["video/webm", "video/mp4"];

    for (var i in codecs) {
      if (MediaRecorder.isTypeSupported(codecs[i])) {
        return codecs[i];
      }
    }

    throw new Error("No supported mediaType found");
  };

  const getMediaStream = async () => {
    mediaType.current = findBestCodec();

    await window.navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: {
          facingMode: "environment",
          frameRate: { max: 30 },
        },
      })
      .then((response) => {
        mediaStream.current = response;
      })
      .catch((reason) => {
        cancel("NO_PERMISSIONS");
        const errorMessage = `Video permissions issue code:${reason.code} name:${reason.name} message:${reason.message}`;
        errorReporter.report(new Error(errorMessage));
        console.error(errorMessage, reason);
        mediaStream.current = null;
      });
  };

  const destroyStream = () => {
    mediaRecorder.current = null;
    mediaStream.current = null;
  };

  const initRecorder = async () => {
    if (!mediaStream.current) {
      await getMediaStream();
    }
    if (mediaStream.current) {
      // User blocked the permissions (getMediaStream errored out)
      if (!mediaStream.current.active) {
        return;
      }

      let mediaRecorderOptions = {
        mimeType: mediaType.current,
      };

      mediaRecorder.current = new MediaRecorder(mediaStream.current, mediaRecorderOptions);
      mediaRecorder.current.ondataavailable = onRecordingActive;
      mediaRecorder.current.onerror = (e) => {
        setStatus("error");
        errorReporter.report(e.error);
      };

      setStatus("ready");
    }
  };

  if (status === "uninit") {
    initRecorder();
  }

  const save = () => {
    const blobProperty = { type: mediaType.current };
    const blob = new Blob(mediaChunks.current, blobProperty);

    props.save(blob);
    destroyStream();
  };

  const StartStopButton = () => {
    switch (status) {
      case "recording":
        return (
          <span onClick={() => stopRecording()} className="video-record-icon">
            <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="33.5" cy="33.5" r="30" stroke="white" strokeWidth="3" />
              <rect x="22" y="22" width="23" height="23" rx="3" fill="#EB5545" />
            </svg>
          </span>
        );
      case "idle":
      case "ready":
        return (
          <span onClick={() => startRecording()} className="video-record-icon text-white">
            <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="33.5" cy="33.5" r="22.5" fill="#FEFEFE" />
              <circle cx="33.5" cy="33.5" r="30" stroke="white" strokeWidth="3" />
            </svg>
          </span>
        );
      case "stopped":
        return (
          <span onClick={() => startRecording()} className="video-record-icon text-white">
            <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="33.5" cy="33.5" r="22.5" fill="#FEFEFE" />
              <circle cx="33.5" cy="33.5" r="30" stroke="white" strokeWidth="3" />
            </svg>
          </span>
        );
      default:
        return (
          <div className="text-center">
            <div className="spinner-border app-spinner" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        );
    }
  };

  const CancelButton = () => {
    let visibility = "visible";

    switch (status) {
      case "recording":
      case "uninit":
        visibility = "invisible";
        break;
      default:
        break;
    }

    return (
      <>
        {status === "ready" ? (
          <button
            className={`btn-video btn btn-outline-warning rounded-pill text-center ${visibility}`}
            type="button"
            onClick={() => cancel()}
          >
            <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.48248 0.650226C7.66132 0.646423 7.83693 0.696551 7.98492 0.793718C8.1329 0.890886 8.2459 1.03029 8.30821 1.19243C8.37051 1.35456 8.37902 1.53141 8.33255 1.69844C8.28608 1.86547 8.18695 2.01428 8.04893 2.12431L2.78277 6.48657L8.04893 10.8473C8.14441 10.9151 8.22436 11.0013 8.2838 11.1004C8.34323 11.1995 8.38086 11.3095 8.39432 11.4233C8.40779 11.5371 8.39681 11.6524 8.36206 11.7619C8.32732 11.8714 8.26956 11.9729 8.1924 12.0598C8.11524 12.1468 8.02035 12.2173 7.91366 12.2671C7.80697 12.317 7.69079 12.345 7.5724 12.3494C7.454 12.3538 7.33593 12.3346 7.22559 12.2929C7.11525 12.2511 7.01501 12.1878 6.93115 12.1069L0.901881 7.1188C0.807199 7.04067 0.731169 6.9437 0.679034 6.83441C0.6269 6.72511 0.599902 6.60616 0.599902 6.4858C0.599902 6.36544 0.6269 6.24649 0.679034 6.1372C0.731169 6.02791 0.807199 5.93084 0.901881 5.85271L6.93115 0.859849C7.08346 0.729038 7.27882 0.654668 7.48248 0.650034V0.650226Z" />
            </svg>

            <span className="ps-2">Back</span>
          </button>
        ) : (
          <button
            className={`btn-video btn btn-outline-danger rounded-pill text-center ${visibility}`}
            type="button"
            onClick={() => cancel()}
          >
            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.50777 5.509L10.7909 1.22569C11.0697 0.94708 11.0697 0.496604 10.7909 0.217992C10.5123 -0.0606197 10.0619 -0.0606197 9.78325 0.217992L5.49994 4.5013L1.21676 0.217992C0.938014 -0.0606197 0.487668 -0.0606197 0.209057 0.217992C-0.0696855 0.496604 -0.0696855 0.94708 0.209057 1.22569L4.49224 5.509L0.209057 9.79232C-0.0696855 10.0709 -0.0696855 10.5214 0.209057 10.8C0.347906 10.939 0.530471 11.0088 0.712907 11.0088C0.895342 11.0088 1.07778 10.939 1.21676 10.8L5.49994 6.5167L9.78325 10.8C9.92223 10.939 10.1047 11.0088 10.2871 11.0088C10.4695 11.0088 10.652 10.939 10.7909 10.8C11.0697 10.5214 11.0697 10.0709 10.7909 9.79232L6.50777 5.509Z" />
            </svg>

            <span className="ps-2">Cancel</span>
          </button>
        )}
      </>
    );
  };

  const AcceptButton = () => {
    let visibility = "visible";

    if (status !== "stopped") {
      visibility = "invisible";
    }

    return (
      <button
        className={`btn-video btn btn-outline-success rounded-pill text-center ${visibility}`}
        type="button"
        onClick={() => save()}
      >
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.7212 0.461362C11.3499 0.0895852 10.747 0.0898196 10.3752 0.461362L4.31744 6.51936L1.62503 3.82697C1.25325 3.45519 0.650609 3.45519 0.278832 3.82697C-0.0929441 4.19874 -0.0929441 4.80139 0.278832 5.17316L3.6442 8.53854C3.82998 8.72431 4.07357 8.81743 4.31719 8.81743C4.5608 8.81743 4.80463 8.72454 4.9904 8.53854L11.7212 1.80753C12.0929 1.43602 12.0929 0.833115 11.7212 0.461362Z" />
        </svg>

        <span className="ps-2">Use</span>
      </button>
    );
  };

  const StatusMessage = () => {
    switch (status) {
      case "recording":
        return (
          <div className="d-flex align-items-center">
            <div className="recording-animation spinner-grow text-danger me-2 ms-1" role="status"></div>
            Recording
          </div>
        );
      case "uninit":
        return <>Requesting permissions...</>;
      case "ready":
        return (
          <div className="d-flex align-items-center text-success">
            <span className="material-symbols-outlined me-2">check</span>
            Ready to record
          </div>
        );
      case "idle":
      case "stopped":
        return (
          <div className="d-flex align-items-center">
            <span className="material-icons text-danger me-2" role="status">
              stop
            </span>
            Stopped
          </div>
        );
      default:
        return <>{status}</>;
    }
  };

  return (
    // if MediaRecorder
    <>
      {!showMediaRecorderModal ? (
        <div>
          <div className="video-status-message fs-6 text-danger px-2">
            <StatusMessage />
          </div>

          <div className="position-relative m-2">
            <VideoPreview stream={mediaStream.current} />
          </div>

          <div className="video-btn-wrap fixed-bottom d-flex align-items-center justify-content-around pb-2 mx-auto col-xxl-9 col-xl-9 col-lg-10">
            <CancelButton />
            <StartStopButton />
            <AcceptButton />
          </div>
        </div>
      ) : (
        <EnableMediaRecorderModal />
      )}
    </>
  );
}

const VideoPreview = ({ stream }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);
  if (!stream) {
    return null;
  }
  return <video ref={videoRef} className="w-100 rounded-3" autoPlay playsInline muted />;
};

export default Video;
